.ContentContainer {
  overflow: hidden;
  width: 100%;

  display: flex;
  flex-direction: column;
}

.Header {
  flex-shrink: 0;

  @apply text-xl font-semibold;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  display: flex;
  align-items: center;

  height: 72px !important;
  padding: 32px 24px 0 24px;
}

.StackContainer {
  flex-grow: 1;
  overflow: hidden;
}

.NotificationContainer {
  width: 100%;
  height: 100%;

  padding: 24px;

  overflow: scroll;
}

.NotificationContentContainer {
  width: 640px;

  padding: 32px 40px;

  border-radius: 12px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);
}
