.SideMenuContainer {
  flex-shrink: 0;

  width: 224px;
  height: 100%;

  padding: 32px 24px;
}

.Title {
  @apply text-3xl font-medium;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  margin-left: 8px;
}

.MenuList {
  display: flex;
  flex-direction: column;
  gap: 8px;

  height: 100%;
  margin-top: 24px;

  overflow: hidden;
  overflow-y: auto;
}

.MenuItem {
  @apply text-sm font-medium;
  color: var(--dop-color-text-basic-level2, #4a4b4c);

  display: flex;
  align-items: center;

  height: 32px;
  padding: 0 8px;
  border-radius: 8px;

  &:hover {
    color: var(--dop-color-text-basic-level1, #1c1c1c);
    background-color: var(--dop-color-bg-basic-base-hover, #f5f6f8);
  }
}

.MenuItem.selected {
  color: var(--dop-color-text-basic-level1, #1c1c1c);
  background-color: var(--dop-color-bg-basic-base-hover, #f5f6f8);
}
