.Container {
  display: flex;

  height: calc(100vh - 240px);
  min-width: 1000px;

  padding: 0 !important;
}

.CloseButton {
  position: absolute;
  top: 24px;
  right: 24px;
}

@media (max-width: 1000px) {
  .Container {
    width: 100vw;
    height: 100vh;
    min-width: 0;

    padding: 0 !important;

    position: absolute !important;
    top: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    left: 0 !important;

    transform: translate(0, 0) !important;
  }
}
