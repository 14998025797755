.ListContainer {
  display: flex;
  flex-direction: column;
  gap: 6px;

  width: 100%;
  height: 100%;
}

.ItemContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;

  width: 100%;

  padding: 8px 0 16px 0;

  cursor: pointer;
}

.ItemContainer.notLast {
  border-bottom: 1px solid var(--dop-color-border-basic-level1, #eaecef);
}

.ItemHeader {
  flex-shrink: 0;

  display: flex;
  align-items: center;

  height: 20px;
}

.ItemCategory {
  @apply text-2xs font-normal;
  color: var(--dop-color-text-basic-level3, #9b9c9e);

  display: flex;
  align-items: center;
  justify-content: center;

  height: 20px;

  padding: 0 8px;

  border-radius: 12px;
  border: 1px solid var(--dop-color-border-basic-level2, #cfd1d4);
}

.ItemDate {
  @apply text-xs font-normal;
  color: var(--dop-color-text-basic-level3, #999999);
}

.ItemTitle {
  @apply text-sm font-medium;
  color: var(--dop-color-text-basic-level1, #1c1c1c);

  max-width: 458px;
}

.NewBadge {
  @apply font-bold;
  color: #ffffff;
  font-size: 9.5px;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 14px;
  height: 14px;

  margin-left: 4px;
  margin-bottom: 3px;

  vertical-align: middle;

  border-radius: 24px;
  background-color: var(--dop-color-bg-status-negative-level1, #ee2e2e);
}

.AlertContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  height: 560px;
}

.AlertMessage {
  @apply text-sm font-medium;
  color: var(--dop-color-text-basic-level3, #9b9c9e);
}
